import './App.css';

function App() {
  return (
    <div className="App">
      <header className='App-header'>
      <h1>Nvidia (NVDA) Financial Analysis</h1>
      </header>


      <div className='App-Intro'>This is an example financial analysis, utilizing a variety of customized financial models to outline a detailed analysis of Nvidia. 
        Through these models, I attempt to showcase an analysis of the company through some financial theory along with visualizing some example custom scenarios involving the corporation.
        
        <p>All 6 models can be customized with Drop-Down features. All values in blue can be customized. They act as base assumptions I made to fully illustrate the models. All values in green are linked values, that which are linked to previous/other model values or to values within the sheets of the same model(workbook).</p></div>

        <p>(Continue to Scroll to the Bottom of This Web Page)</p>
        <hr className='line'></hr>

      <div className='excel'>
        <p>Three Statment Model</p>
      <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21257&authkey=%21AKToJwgsXHZlbxY&em=2&AllowTyping=True&ActiveCell='Financial%20Statement%20Model'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='3STMT'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='excel'>
        <p>Trading Comparative Analysis</p>
      <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21263&authkey=%21ADJDdwZ3ZyZGx2Q&em=2&AllowTyping=True&ActiveCell='Summary%20Output'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='TC'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='excel'>
        <p>Transaction Comparative Analysis</p>
      <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21265&authkey=%21AKV55gAkTm7o4tM&em=2&AllowTyping=True&ActiveCell='Precedent%20Transaction%20Output'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='TRC'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='excel'>
        <p>Discounted Cash Flow Model</p>
        <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21276&authkey=%21AO3eZyyyzHB8Uc8&em=2&AllowTyping=True&ActiveCell='DCF'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='DCF'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='excel'>
        <p>Leveraged Buyout Model</p>
        <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21278&authkey=%21AKFv6JUNH0cUWjg&em=2&AllowTyping=True&ActiveCell='LBO'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='LBO'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='excel'>
        <p>Merger Model</p>
      <iframe width="1300" height="700" frameborder="0" scrolling="no" src="https://onedrive.live.com/embed?resid=4cef457bb869b141%21280&authkey=%21APuTHtC86DYuDWw&em=2&AllowTyping=True&ActiveCell='Detailed%20Acquire'!A1&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True" title='MA'></iframe>
      </div>

      <hr className='line'></hr>

      <div className='App-footer'>Created by Brando Pakel -- Financial Model Templates by Wall Street Oasis</div>
    </div>
  );
}

export default App;
